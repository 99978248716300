import "../../assets/styles/components/menu.scss"

const MenuElement = (props) => {

  const outlinedStyle = {
    borderTop: props.outlineBorderTop && "2px solid grey",
    borderBottom: props.outlineBorderBottom && "2px solid grey"
  }

  const editable = window.location.search === '?edit';

  return (
    <div
      className={`${"element"}`}
      style={outlinedStyle}
    >
      <div className={`${props.outlined ? "outline" : "not/outline"}`}>

        {
          editable
            ?
              <input
                className="editable_input"
                type="text"
                id="fname"
                name="fname"
                value={props.title}
                onChange={(e) => {props.onChangeTitle(props.uuid, props.location, e.target.value)}}
              />
            :
            <h1>
              { props.title }
            </h1>
        }
        {
          editable
            ?
              <input
                className="editable_input"
                type="text"
                id="fname"
                name="fname"
                value={props.volume}
                onChange={(e) => {props.onChangeVolume(props.uuid, props.location, e.target.value)}}
              />
            :
              <h2 style={{lineHeight: "14px", marginTop: "2px"}}>
                { props.volume }
              </h2>
        }

      </div>
      <div>
        {
          editable
            ?
              <input
                className="editable_input"
                type="text"
                id="fname"
                name="fname"
                value={props.price}
                onChange={(e) => {props.onChangePrice(props.uuid, props.location, e.target.value)}}
              />
            :
              <h1 style={{  whiteSpace: "nowrap"}}>
                { props.price }
              </h1>
        }

      </div>
    </div>
  )
}

export default MenuElement