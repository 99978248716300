
export const borovickaList = [
  {
    title: "Lúčky gin",
    volume: "40 ml",
    outlined: true,
    outlinedTop: true,
    price: "2,10 €"
  },
  {
    title: "Lúčky pink gin",
    volume: "40 ml",
    outlined: true,
    outlinedBottom: true,
    price: "2,50 €"
  },
  {
    title: "Borovička slovenská",
    volume: "40 ml",
    price: "1,10 €"
  },
  {
    title: "Borovička koniferum",
    volume: "40 ml",
    price: "1,50 €"
  },
]





export const rumList = [
  {
    title: "Captain Morgan 35 %",
    volume: "40 ml",
    price: "1,80 €"
  },
  {
    title: "Havana especial rum 40 %",
    volume: "40 ml",
    price: "1,90 €"
  },
  {
    title: "Brugal 1888 40 %",
    volume: "40 ml - 14r.",
    price: "4,20 €"
  },
  {
    title: "Diplomatico Reserva 40 %",
    volume: "40 ml - 12r.",
    price: "4,40 €"
  },
  {
    title: "ZACAPA CENTENARIO 40 %",
    volume: "40 ml - 25r.",
    price: "5,80 €"
  },
]


export const whiskeyList = [
  {
    title: "Jameson 40 %",
    volume: "40 ml",
    price: "2,60 €"
  },
  {
    title: "Tullamore Dew 40 %",
    volume: "40 ml",
    price: "2,60 €"
  },
  {
    title: "Jack Daniels 40 %",
    volume: "40 ml",
    price: "2,70 €"
  },
]

export const cognacList = [
  {
    title: "Hennessy V.S. 40 %",
    volume: "40 ml",
    price: "4,00 €"
  }
]
