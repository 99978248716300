import { useState } from "react"

import main_view_card_styles from "../../assets/styles/components/mainViewCard.module.scss"
import cardTwoImage from "../../assets/images/about_us/about_1.jpg"
import cardTwoImageTwo from "../../assets/images/about_us/about_2.jpg"


const IntroCard = () => {

  const [rowOneAnimation, setRowOneAnimation] = useState(main_view_card_styles.second_card__row_one_animation_hide)
  const [rowTwoAnimation, setRowTwoAnimation] = useState(main_view_card_styles.second_card__row_two_animation_hide)

  window.addEventListener('scroll', function() {
    var rowOne = document.querySelector('#INTRO_CARD_ROW_ONE');
    var rowTwo = document.querySelector('#INTRO_CARD_ROW_TWO');

    var positionOne = rowOne.getBoundingClientRect();
    var positionTwo = rowTwo.getBoundingClientRect();

    if(positionOne.top < window.innerHeight && positionOne.bottom >= 0) {
      if (window.innerHeight - positionOne.top > 250) {
        setRowOneAnimation(main_view_card_styles.second_card__row_one_animation_show)
      } else {
        setRowOneAnimation(main_view_card_styles.second_card__row_one_animation_hide)
      }
    } else {
      setRowOneAnimation(main_view_card_styles.second_card__row_one_animation_hide)
    }

    if(positionTwo.top < window.innerHeight && positionTwo.bottom >= 0) {
      if (window.innerHeight - positionTwo.top > 250) {
        setRowTwoAnimation(main_view_card_styles.second_card__row_two_animation_show)
      } else {
        setRowTwoAnimation(main_view_card_styles.second_card__row_two_animation_hide)
      }
    } else {
      setRowTwoAnimation(main_view_card_styles.second_card__row_two_animation_hide)
    }
  });

  return (
    <div
      id="INTRO_CARD"
      className={main_view_card_styles.second_card}
    >
        <div
          id="INTRO_CARD_ROW_ONE"
          className={`${main_view_card_styles.second_card__row_one} ${rowOneAnimation}`}
        >
            <div className="img">
              <img src={cardTwoImage} alt="" />
            </div>
            <div id="INTRO_CARD_TITLE_ID" className="text">
              <h1>
                MARACANA
              </h1>
              <h2>
                Ľudia hovoria, že šťastie si za peniaze nekúpite. Klamú. Za peniaze si môžete kúpiť kávu, a káva vás robí šťastnými!
              </h2>
            </div>
        </div>
        <div
          id="INTRO_CARD_ROW_TWO"
          className={`${main_view_card_styles.second_card__row_two} ${rowTwoAnimation}`}
        >
            <div>
              <p>
              Všetci vieme kde sa Maracana nachádza a ako vyzerá. Je to najväčší futbalový štadión v Brazilií.
              Všetci z Nás by sme si to miesto chceli priblížiť a týmto sme si na mnohých miestach v Našej krajine už ako deti pomenovali miesta,
              kde sme hrávali futbal rovnomenne. Jednym z takýchto miest je aj ihrisko neďaleko Našej kaviarne. Slúžilo ako miesto pre oddych, či šport mladých ľudí.
              Preto sme sa rozhodli tuto myšlienku vdýchnuť do Nášho podniku Maracana, ktorý nájdete v dedinke Hankovce.
              Miesto ponúka kaviareň & bar so skvelým výberom drinkov, vína, kávy či piva. V lete je dispozicií krytá terasa.
              </p>{/*
              <h4>
                Tím Maracana
              </h4> */}
            </div>
            <img src={cardTwoImageTwo} alt="" />
        </div>

    </div>
  )
}

export default IntroCard