import main_view_card_styles from "../../assets/styles/components/mainViewCard.module.scss"
import MenuSection from "../menu/MenuSection"

import {
  MenuSectionFrontLeft,
  MenuSectionFrontRight,
  MenuSectionBackLeft,
  MenuSectionBackRight
} from "../../config/menu"
import { useState } from "react"

import cocktailMenu from "../../assets/images/cocktail_menu/cocktail_menu.jpg"
import pizzaMenu from "../../assets/images/pizza_menu/pizza_menu.jpg"
import CocktailMenu from "../menu/CocktailMenu/CocktailMenu"

const MenuCard = () => {

  const [menuFrontLeft, setmenuFrontLeft] = useState(MenuSectionFrontLeft)
  const [menuFrontRight, setmenuFrontRight] = useState(MenuSectionFrontRight)
  const [menuBackLeft, setmenuBackLeft] = useState(MenuSectionBackLeft)
  const [menuBackRight, setmenuBackRight] = useState(MenuSectionBackRight)

  const [rowOneAnimation, setRowOneAnimation] = useState(main_view_card_styles.second_card__row_one_animation_hide)
  const [rowTwoAnimation, setRowTwoAnimation] = useState(main_view_card_styles.second_card__row_two_animation_hide)
  const [titleAnimation, setTitleAnimation] = useState(main_view_card_styles.fifth_card_title_animation_hide)

  const [coctailsAnimation, setCoctailsAnimation] = useState(main_view_card_styles.second_card__row_one_animation_hide)
 /*  const [pizzaAnimation, setPizzaAnimation] = useState(main_view_card_styles.second_card__row_two_animation_hide) */

  window.addEventListener('scroll', function() {
    var title = document.querySelector('#MENU_CARD_TITLE_ID');
    var menuOne = document.querySelector('#MENU_CARD_ROW_ONE_ID');
    var rowTwo = document.querySelector('#MENU_CARD_ROW_TWO_ID');

    var coctails = document.querySelector('#MENU_COCKTAIL_MENU_ID');
    /* var pizza = document.querySelector('#MENU_PIZZA_MENU_ID'); */

    var positionTitle = title.getBoundingClientRect();
    var positionOne = menuOne.getBoundingClientRect();
    var positionTwo = rowTwo.getBoundingClientRect();

    var positionCoctails = coctails.getBoundingClientRect();
    /* var positionPizza = pizza.getBoundingClientRect(); */

    if(positionTitle.top < window.innerHeight && positionTitle.bottom >= 0) {
      if (window.innerHeight - positionTitle.top > 250) {
        setTitleAnimation(main_view_card_styles.fifth_card_title_animation_show)
      } else {
        setTitleAnimation(main_view_card_styles.fifth_card_title_animation_hide)
      }
    } else {
      setTitleAnimation(main_view_card_styles.fifth_card_title_animation_hide)
    }

    if(positionOne.top < window.innerHeight && positionOne.bottom >= 0) {
      if (window.innerHeight - positionOne.top > 250) {
        setRowOneAnimation(main_view_card_styles.second_card__row_one_animation_show)
      } else {
        setRowOneAnimation(main_view_card_styles.second_card__row_one_animation_hide)
      }
    } else {
      setRowOneAnimation(main_view_card_styles.second_card__row_one_animation_hide)
    }

    if(positionTwo.top < window.innerHeight && positionTwo.bottom >= 0) {
      if (window.innerHeight - positionTwo.top > 250) {
        setRowTwoAnimation(main_view_card_styles.second_card__row_two_animation_show)
      } else {
        setRowTwoAnimation(main_view_card_styles.second_card__row_two_animation_hide)
      }
    } else {
      setRowTwoAnimation(main_view_card_styles.second_card__row_two_animation_hide)
    }

    if(positionCoctails.top < window.innerHeight && positionCoctails.bottom >= 0) {
      if (window.innerHeight - positionCoctails.top > 250) {
        setCoctailsAnimation(main_view_card_styles.second_card__row_one_animation_show)
      } else {
        setCoctailsAnimation(main_view_card_styles.second_card__row_one_animation_hide)
      }
    } else {
      setCoctailsAnimation(main_view_card_styles.second_card__row_one_animation_hide)
    }

    /* if(positionPizza.top < window.innerHeight && positionPizza.bottom >= 0) {
      if (window.innerHeight - positionPizza.top > 250) {
        setPizzaAnimation(main_view_card_styles.second_card__row_two_animation_show)
      } else {
        setPizzaAnimation(main_view_card_styles.second_card__row_two_animation_hide)
      }
    } else {
      setPizzaAnimation(main_view_card_styles.second_card__row_two_animation_hide)
    } */

  });

  const editable = window.location.search === '?edit';

  const downloadMenuButtonHandler = () => {
    const lists = [
      // FrontLeftSide
      ['export const nealkoList = ','export const kavickaList = ','export const cajList = '],
      // FrontRightSide
      ['export const pochutinyList = ','export const pivkoList = ','export const vinkoList = '],
      // BackLeftSide
      ['export const vodkaList = ','export const borovickaList = ','export const bvdList = ','export const rumList = ','export const cognacList = ','export const tequilaList = '],
      // BackRightSide
      ['export const prezenyList = ','export const whiskeyList = ','export const likerList = ','export const tatrateaList = ','export const destilatyList = ']
    ]

    const frontLeft = menuFrontLeft.map(item => { return item.list})
    const frontRight = menuFrontRight.map(item => { return item.list})
    const backLeft = menuBackLeft.map(item => { return item.list})
    const backRight = menuBackRight.map(item => { return item.list})

    let frontLeftListResult = ``
    let frontRightListResult = ``
    let backLeftListResult = ``
    let backRightListResult = ``


    frontLeft.forEach((section, index) => {
      let localList = ""
      section.forEach(item => {
        localList = localList + JSON.stringify(item) + ','
      })

      frontLeftListResult = frontLeftListResult + `${lists[0][index]}[${localList}]\n`
    })

    frontRight.forEach((section, index) => {
      let localList = ""
      section.forEach(item => {
        localList = localList + JSON.stringify(item) + ','
      })

      frontRightListResult = frontRightListResult + `${lists[1][index]}[${localList}]\n`
    })

    backLeft.forEach((section, index) => {
      let localList = ""
      section.forEach(item => {
        localList = localList + JSON.stringify(item) + ','
      })

      backLeftListResult = backLeftListResult + `${lists[2][index]}[${localList}]\n`
    })

    backRight.forEach((section, index) => {
      let localList = ""
      section.forEach(item => {
        localList = localList + JSON.stringify(item) + ','
      })

      backRightListResult = backRightListResult + `${lists[3][index]}[${localList}]\n`
    })

    const names = [
      'FrontLeftSide.js',
      'FrontRightSide.js',
      'BackLeftSide.js',
      'BackRightSide.js'
    ]

    for (let index = 0; index < 4; index++) {
      let result = ""
      switch (index) {
        case 0:
          result = frontLeftListResult
          break;
        case 1:
          result = frontRightListResult
          break;
        case 2:
          result = backLeftListResult
          break;
        case 3:
          result = backRightListResult
          break;

        default:
          break;
      }

      var dataStr = "data:text/plain;charset=utf-8," + encodeURIComponent(result);
      var dlAnchorElem = document.getElementById('downloadAnchorElem');
      dlAnchorElem.setAttribute("href",     dataStr     );
      dlAnchorElem.setAttribute("download", names[index]);
      dlAnchorElem.click();

    }


  }

  const onChangeTitleHandler = (item_uuid, location, new_value) => {
    switch (location) {
      case 'frontLeft':
        setmenuFrontLeft((prevState) => {
          return prevState.map(element => {
            return {
              ...element,
              list: element.list.map(item => {
                if (item.uuid === item_uuid) {
                  return {
                    ...item,
                    title: new_value
                  }
                }
                return item
              })
            }
          })
        })
        break;
      case 'frontRight':
        setmenuFrontRight((prevState) => {
          return prevState.map(element => {
            return {
              ...element,
              list: element.list.map(item => {
                if (item.uuid === item_uuid) {
                  return {
                    ...item,
                    title: new_value
                  }
                }
                return item
              })
            }
          })
        })
        break;
      case 'backLeft':
        setmenuBackLeft((prevState) => {
          return prevState.map(element => {
            return {
              ...element,
              list: element.list.map(item => {
                if (item.uuid === item_uuid) {
                  return {
                    ...item,
                    title: new_value
                  }
                }
                return item
              })
            }
          })
        })
        break;
      case 'backRight':
        setmenuBackRight((prevState) => {
          return prevState.map(element => {
            return {
              ...element,
              list: element.list.map(item => {
                if (item.uuid === item_uuid) {
                  return {
                    ...item,
                    title: new_value
                  }
                }
                return item
              })
            }
          })
        })
        break;

      default:
        break;
    }
  }
  const onChangeVolumeHandler = (item_uuid, location, new_value) => {
    switch (location) {
      case 'frontLeft':
        setmenuFrontLeft((prevState) => {
          return prevState.map(element => {
            return {
              ...element,
              list: element.list.map(item => {
                if (item.uuid === item_uuid) {
                  return {
                    ...item,
                    volume: new_value
                  }
                }
                return item
              })
            }
          })
        })
        break;
      case 'frontRight':
        setmenuFrontRight((prevState) => {
          return prevState.map(element => {
            return {
              ...element,
              list: element.list.map(item => {
                if (item.uuid === item_uuid) {
                  return {
                    ...item,
                    volume: new_value
                  }
                }
                return item
              })
            }
          })
        })
        break;
      case 'backLeft':
        setmenuBackLeft((prevState) => {
          return prevState.map(element => {
            return {
              ...element,
              list: element.list.map(item => {
                if (item.uuid === item_uuid) {
                  return {
                    ...item,
                    volume: new_value
                  }
                }
                return item
              })
            }
          })
        })
        break;
      case 'backRight':
        setmenuBackRight((prevState) => {
          return prevState.map(element => {
            return {
              ...element,
              list: element.list.map(item => {
                if (item.uuid === item_uuid) {
                  return {
                    ...item,
                    volume: new_value
                  }
                }
                return item
              })
            }
          })
        })
        break;

      default:
        break;
    }
  }
  const onChangePriceHandler = (item_uuid, location, new_value) => {
    switch (location) {
      case 'frontLeft':
        setmenuFrontLeft((prevState) => {
          return prevState.map(element => {
            return {
              ...element,
              list: element.list.map(item => {
                if (item.uuid === item_uuid) {
                  return {
                    ...item,
                    price: new_value
                  }
                }
                return item
              })
            }
          })
        })
        break;
      case 'frontRight':
        setmenuFrontRight((prevState) => {
          return prevState.map(element => {
            return {
              ...element,
              list: element.list.map(item => {
                if (item.uuid === item_uuid) {
                  return {
                    ...item,
                    price: new_value
                  }
                }
                return item
              })
            }
          })
        })
        break;
      case 'backLeft':
        setmenuBackLeft((prevState) => {
          return prevState.map(element => {
            return {
              ...element,
              list: element.list.map(item => {
                if (item.uuid === item_uuid) {
                  return {
                    ...item,
                    price: new_value
                  }
                }
                return item
              })
            }
          })
        })
        break;
      case 'backRight':
        setmenuBackRight((prevState) => {
          return prevState.map(element => {
            return {
              ...element,
              list: element.list.map(item => {
                if (item.uuid === item_uuid) {
                  return {
                    ...item,
                    price: new_value
                  }
                }
                return item
              })
            }
          })
        })
        break;

      default:
        break;
    }
  }

  return (
    <div
      className={main_view_card_styles.fifth_card}
    >
        <div
          id="MENU_CARD_TITLE_ID"
          className={`${main_view_card_styles.fifth_card__row_one} ${titleAnimation}`}
        >
            <h2>
              Naše
            </h2>
            <h1>
              MENU
            </h1>
        </div>
        <div
          id="MENU_COCKTAIL_MENU_ID"
          className={`${main_view_card_styles.fifth_card__cocktail_menu} ${coctailsAnimation}`}
          style={{marginTop: "15px", gap: "15px"}}
        >
          {/* <CocktailMenu /> */}
          {/* <img src={cocktailMenu} alt="" srcSet="" />
          <img src={pizzaMenu} alt="" srcSet="" /> */}
        </div>
        {/* <div
          id="MENU_PIZZA_MENU_ID"
          className={`${main_view_card_styles.fifth_card__pizza_menu} ${pizzaAnimation}`}
          style={{marginTop: "15px", gap: "15px"}}
        >
          <img src={pizzaMenu} alt="" srcSet="" />
        </div> */}
        <div
          id="MENU_CARD_ROW_ONE_ID"
          className={`${main_view_card_styles.fifth_card__row_two} ${rowOneAnimation}`}
          style={{marginTop: "15px", gap: "15px"}}
        >
          <div className={main_view_card_styles.fifth_card__row_two_col_one}>
            {
              menuFrontLeft.map((section, index) =>
                <MenuSection
                  key={index}
                  icon={section.icon}
                  title={section.title}
                  list={section.list}
                  onChangeTitle={onChangeTitleHandler}
                  onChangeVolume={onChangeVolumeHandler}
                  onChangePrice={onChangePriceHandler}
                />
              )
            }
          </div>
          <div className={main_view_card_styles.fifth_card__row_two_col_two}>
            {
              menuFrontRight.map((section, index) =>
                <MenuSection
                  key={index}
                  outlined={section.outlined}
                  icon={section.icon}
                  title={section.title}
                  list={section.list}
                  onChangeTitle={onChangeTitleHandler}
                  onChangeVolume={onChangeVolumeHandler}
                  onChangePrice={onChangePriceHandler}
                />
              )
            }
          </div>
        </div>
        <div
          id="MENU_CARD_ROW_TWO_ID"
          className={`${main_view_card_styles.fifth_card__row_two} ${rowTwoAnimation}`}
          style={{marginTop: "15px", gap: "15px"}}
        >
          <div className={main_view_card_styles.fifth_card__row_two_col_one}>
            {
              menuBackLeft.map((section, index) =>
                <MenuSection
                  key={index}
                  outlined={section.outlined}
                  icon={section.icon}
                  title={section.title}
                  list={section.list}
                  onChangeTitle={onChangeTitleHandler}
                  onChangeVolume={onChangeVolumeHandler}
                  onChangePrice={onChangePriceHandler}
                />
              )
            }
          </div>
          <div className={main_view_card_styles.fifth_card__row_two_col_two}>
            {
              menuBackRight.map((section, index) =>
                <MenuSection
                  key={index}
                  outlined={section.outlined}
                  icon={section.icon}
                  title={section.title}
                  list={section.list}
                  onChangeTitle={onChangeTitleHandler}
                  onChangeVolume={onChangeVolumeHandler}
                  onChangePrice={onChangePriceHandler}
                />
              )
            }
          </div>
        </div>
        {
          editable &&
          <div className="download_menu_button_wrap">
            <a id="downloadAnchorElem" style={{display: "none"}}></a>
            <button
              className="download_menu_button"
              onClick={downloadMenuButtonHandler}
            >
              Stiahnuť upravené menu
            </button>

          </div>
        }
    </div>
  )
}

export default MenuCard