import { Link } from "react-router-dom";
import img from "../../assets/images/header_logo.png"
const ErrorPage = () => {
  const mainColor = "#bca792";
  const bgColor = "#2a2a2a";

  const pageStyles = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    backgroundColor: bgColor,
    color: mainColor,
  };

  const buttonStyles = {
    backgroundColor: "#f4ddc5",
    color: bgColor,
    fontWeight: "bold",
    border: "none",
    padding: "0.5rem 1rem",
    borderRadius: "4px",
    marginTop: "1rem",
    cursor: "pointer",
  };

  const imgStyles = {
    borderRadius: '10px',
    maxWidth: "180px"
  }

  return (
    <div style={pageStyles}>
      <img src={img} alt="Maracana" style={imgStyles}/>
      <h1>Niečo sa pokazilo</h1>
      <h3 style={{textAlign: "center", maxWidth: "490px"}}>
        Skúste to znova neskôr, alebo choďte radšej na kávu.
        My Vás hneď odtiaľto presmerujeme na našu stránku.
      </h3>
      <Link to="/">
        <button style={buttonStyles}>Späť na web</button>
      </Link>
      <Link to="/?menu">
        <button style={buttonStyles}>Späť na web a priamo na menu</button>
      </Link>
    </div>
  );
};

export default ErrorPage;
