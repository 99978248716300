
import Home from "../components/pages/Home"

const HomePage = () => {
  return (
    <Home />
  )
}

export default HomePage

