export const days = [
  {
    day: "Pondelok",
    opening: "Zatvorené"
  },
  {
    day: "Utorok",
    opening: "16:00 - 22:00"
  },
  {
    day: "Streda",
    opening: "16:00 - 22:00"
  },
  {
    day: "Štvrtok",
    opening: "16:00 - 22:00"
  },
  {
    day: "Piatok",
    opening: "16:00 - 23:00"
  },
  {
    day: "Sobota",
    opening: "15:00 - 23:00"
  },
  {
    day: "Nedeľa",
    opening: "15:00 - 21:00"
  }
]