import home_page_styles from "../../assets/styles/pages/homePage.module.scss"
import main_view_card_styles from "../../assets/styles/components/mainViewCard.module.scss"

import MainViewCard from "../cards/MainViewCard";

import maracana_text from "../../assets/images/logo_text.svg"

import backgroundImageDesktop from "../../assets/images/backgrounds/intro_bg.jpg"
import backgroundImageMobile from "../../assets/images/backgrounds/intro_bg_phone.jpg"

import thirdBackgroundImageAsset from "../../assets/images/backgrounds/coffee_done_2.jpg"
import fourthBackgroundImageAsset from "../../assets/images/backgrounds/events_bg.jpg"
import fourthBackgroundImageAssetMobile from "../../assets/images/backgrounds/events_bg_mobile.jpg"
import shopbgasset from "../../assets/images/backgrounds/eshop_bg_mobile.jpg"
import fifthBackgroundImageAsset from "../../assets/images/backgrounds/gallery_bg.jpg"
import fifthBackgroundImageAssetMobile from "../../assets/images/backgrounds/gallery_bg_mobile.jpg"

import { useState } from "react";
import IntroCard from "../contentCards/IntroCard";
import ExperienceCard from "../contentCards/ExperienceCard";
import MenuCard from "../contentCards/MenuCard";
import ShopCard from "../contentCards/ShopCard";
import GalleryCard from "../contentCards/GalleryCard";
import Footer from "../main/Footer";
import StaffCard from "../contentCards/StaffCard";
import EventsCard from "../contentCards/EventsCard";

import { visible_sections } from "../../config/sectionConfig";

const setViewport = () => {
    return {
        width: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
        height: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    }
}
const updateBackgroundImageGlob = (width, firstImg, secondImg) => {
    return width < 425 ? firstImg  : secondImg
}



const Home = () => {
    let viewPort = setViewport()

    const [firstBackgroundImage, setFirstBackgroundImage] = useState(updateBackgroundImageGlob(viewPort.width, backgroundImageMobile, backgroundImageDesktop))
    const [thirdBackgroundImage, setThirdBackgroundImage] = useState(updateBackgroundImageGlob(viewPort.width, thirdBackgroundImageAsset, thirdBackgroundImageAsset))
    const [fourthBackgroundImage, setFourthBackgroundImage] = useState(updateBackgroundImageGlob(viewPort.width, fourthBackgroundImageAssetMobile, fourthBackgroundImageAsset))
    const [fifthBackgroundImage, setFifthBackgroundImage] = useState(updateBackgroundImageGlob(viewPort.width, fifthBackgroundImageAssetMobile, fifthBackgroundImageAsset))
    const [shopBackgroundImage, setShopBackgroundImage] = useState(updateBackgroundImageGlob(viewPort.width, shopbgasset, shopbgasset))

    window.addEventListener("resize", () => {
        viewPort = setViewport()
        setFirstBackgroundImage(viewPort.width < 425 ? backgroundImageMobile : backgroundImageDesktop)
        setThirdBackgroundImage(viewPort.width < 425 ? thirdBackgroundImageAsset : thirdBackgroundImageAsset)
        setFourthBackgroundImage(viewPort.width < 425 ? fourthBackgroundImageAssetMobile : fourthBackgroundImageAsset)
        setFifthBackgroundImage(viewPort.width < 425 ? fifthBackgroundImageAssetMobile : fifthBackgroundImageAsset)
        setShopBackgroundImage(viewPort.width < 425 ? shopbgasset : shopbgasset)
        /* backgroundImage = setBackgroundImage(viewPort.width) */
    })

    return (
        <div className={home_page_styles.home_page}>
            <MainViewCard
                dark
                darkenImg
                alignCenter
                justifyCenter
                includeBrush
                onlyBottomBrush
                backgroundImage={firstBackgroundImage}
                isMobile={viewPort.width < 425}
            >
                <div className={main_view_card_styles.first_card}>
                    <div className={main_view_card_styles.first_card__animation}>
                       {/*  <h1>
                            Maracana
                        </h1> */}
                        <img
                            src={maracana_text}
                            alt=""
                        />
                        {/* <h2>
                            Cafe & Bar
                        </h2>
                        <h2>
                            * Od 2018 *
                        </h2> */}
                    </div>
                </div>
            </MainViewCard>
            {
                visible_sections.about_us.visibile &&
                <MainViewCard
                    id={ visible_sections.about_us.id}
                    light
                    className={main_view_card_styles.main_view_card__padding}
                >
                    <IntroCard />
                </MainViewCard>
            }

            {
                visible_sections.local_suppliers.visibile &&
                <MainViewCard
                    id={visible_sections.local_suppliers.id}
                    includeBrush
                    dark
                    darkenImg
                    backgroundImage={thirdBackgroundImage}
                    className={main_view_card_styles.main_view_card__padding}
                >
                    <div className={main_view_card_styles.third_card}>
                        <div className={main_view_card_styles.third_card__animation}>
                            <h2>
                                Naše
                            </h2>
                            <h1>
                                Značky
                            </h1>
                        </div>
                    </div>
                </MainViewCard>
            }
            {
                visible_sections.local_suppliers.visibile &&
                <MainViewCard
                    light
                    className={main_view_card_styles.main_view_card__padding}
                >
                    <ExperienceCard />
                </MainViewCard>
            }

            {
                visible_sections.menu.visibile &&
                <MainViewCard
                    id={visible_sections.menu.id}
                    light
                    className={main_view_card_styles.main_view_card__padding}
                >
                    <MenuCard />
                </MainViewCard>
            }

            {
                visible_sections.staff.visibile &&
                <MainViewCard
                    id={visible_sections.staff.id}
                    includeBrush
                    dark
                    darkenImg
                    className={main_view_card_styles.main_view_card__padding}
                    backgroundImage={fourthBackgroundImage}
                >
                    <div className={main_view_card_styles.third_card}>
                        <div>
                            <h2>
                                Náš
                            </h2>
                            <h1>
                                Personál
                            </h1>
                        </div>
                    </div>
                </MainViewCard>
            }

            {
                visible_sections.staff.visibile &&
                <MainViewCard
                    light
                    alignCenter
                    className={main_view_card_styles.gallery_card__content}
                >
                    <StaffCard />
                </MainViewCard>
            }

            {
                visible_sections.events.visibile &&
                <MainViewCard
                    id={visible_sections.events.id}
                    includeBrush
                    dark
                    darkenImg
                    className={main_view_card_styles.main_view_card__padding}
                    backgroundImage={fourthBackgroundImage}
                >
                    <div className={main_view_card_styles.third_card}>
                        <div>
                            <h2>
                                Naše
                            </h2>
                            <h1>
                                Eventy
                            </h1>
                        </div>
                    </div>
                </MainViewCard>
            }

            {
                visible_sections.events.visibile &&
                <MainViewCard
                    light
                    className={main_view_card_styles.main_view_card__padding}
                >
                    <EventsCard />
                </MainViewCard>
            }

            {
                visible_sections.shop.visibile &&
                <MainViewCard
                    id={visible_sections.shop.id}
                    includeBrush
                    dark
                    darkenImg
                    className={main_view_card_styles.main_view_card__padding}
                    backgroundImage={shopBackgroundImage}
                >
                    <div className={main_view_card_styles.third_card}>
                        <div>
                            <h2>
                                Náš
                            </h2>
                            <h1>
                                E-shop
                            </h1>
                        </div>
                    </div>
                </MainViewCard>
            }
            {
                visible_sections.shop.visibile &&
                <MainViewCard
                    light
                    className={main_view_card_styles.main_view_card__padding}
                >
                    <ShopCard />
                </MainViewCard>
            }

            {
                visible_sections.gallery.visibile &&
                <MainViewCard
                    id={visible_sections.gallery.id}
                    includeBrush
                    dark
                    darkenImg
                    className={main_view_card_styles.main_view_card__padding}
                    backgroundImage={fifthBackgroundImage}
                >
                    <div className={main_view_card_styles.third_card}>
                        <div>
                            <h2>
                                Galéria
                            </h2>
                            <h1>
                                Akcií
                            </h1>
                        </div>
                    </div>
                </MainViewCard>
            }

            {
                visible_sections.gallery.visibile &&
                <MainViewCard
                    light
                    alignCenter
                    className={main_view_card_styles.gallery_card__content}
                >
                    <GalleryCard/>

                </MainViewCard>
            }

            <MainViewCard
                includeBrush
                dark
                className={main_view_card_styles.footer__content}
                noBottomBrush
            >
                <Footer/>
            </MainViewCard>
        </div>
    );
}

export default Home;

