import { useEffect } from "react"
import classes from "../../assets/styles/components/header.module.scss"

import { visible_sections } from "../../config/sectionConfig"

const HeaderMenuLeft = (props) => {

  const hiddenOnPCClass = classes.left_menu_hidden_on_pc

  const visibleSectionsObjToArray = Object.values(visible_sections).filter(val => val.visibile)

  const scrollTo = (dest_element_id) => {
    document
      .getElementById(dest_element_id)
      .scrollIntoView({behavior: 'smooth'}, true)
    setTimeout(() => {
      props.onCloseMenu()
    }, 200);
  }


  useEffect(() => {
    if (window.location.href.includes("menu")) {
      setTimeout(() => {
        scrollTo('MENU_ID')
      }, 1000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {
        visibleSectionsObjToArray.map((obj, index) =>
          <li
            key={index}
            className={{...obj.hiddenOnPc, ...hiddenOnPCClass}}
            onClick={() => scrollTo(obj.id)}
          >
            {
              obj.title
            }
          </li>
        )
      }
    </>
  )
}

export default HeaderMenuLeft