import main_view_card_styles from "../../assets/styles/components/mainViewCard.module.scss"

import bvd from "../../assets/images/local_products/bvd_1.jpeg"
import bvd_logo from "../../assets/images/local_products/bvd_logo.png"

import vlcie from "../../assets/images/local_products/vlcie_1.jpg"
import vlcie_logo from "../../assets/images/local_products/vlcie_logo.jpg"

import coffein from "../../assets/images/local_products/coffein_1.jpeg"
import coffein_logo from "../../assets/images/local_products/coffein_logo.png"

/* import vlcie_logo from "../../assets/images/takeway_stamp.png" */


import { useState } from "react"

const ExperienceCard = () => {



  const [rowOneAnimation, setRowOneAnimation] = useState(main_view_card_styles.second_card__row_one_animation_hide)
  const [rowTwoAnimation, setRowTwoAnimation] = useState(main_view_card_styles.second_card__row_two_animation_hide)
  const [rowThreeAnimation, setRowThreeAnimation] = useState(main_view_card_styles.second_card__row_one_animation_hide)

  window.addEventListener('scroll', function() {
    var rowOne = document.querySelector('#EXPERIENCE_CARD_ROW_ONE');
    var rowTwo = document.querySelector('#EXPERIENCE_CARD_ROW_TWO');
    var rowThree = document.querySelector("#EXPERIENCE_CARD_ROW_THREE")

    var positionOne = rowOne.getBoundingClientRect();
    var positionTwo = rowTwo.getBoundingClientRect();
    var positionThree = rowThree.getBoundingClientRect();

    if(positionOne.top < window.innerHeight && positionOne.bottom >= 0) {
      if (window.innerHeight - positionOne.top > 250) {
        setRowOneAnimation(main_view_card_styles.second_card__row_one_animation_show)
      } else {
        setRowOneAnimation(main_view_card_styles.second_card__row_one_animation_hide)
      }
    } else {
      setRowOneAnimation(main_view_card_styles.second_card__row_one_animation_hide)
    }

    if(positionTwo.top < window.innerHeight && positionTwo.bottom >= 0) {
      if (window.innerHeight - positionTwo.top > 250) {
        setRowTwoAnimation(main_view_card_styles.second_card__row_two_animation_show)
      } else {
        setRowTwoAnimation(main_view_card_styles.second_card__row_two_animation_hide)
      }
    } else {
      setRowTwoAnimation(main_view_card_styles.second_card__row_two_animation_hide)
    }

    if(positionThree.top < window.innerHeight && positionThree.bottom >= 0) {
      if (window.innerHeight - positionThree.top > 250) {
        setRowThreeAnimation(main_view_card_styles.second_card__row_one_animation_show)
      } else {
        setRowThreeAnimation(main_view_card_styles.second_card__row_one_animation_hide)
      }
    } else {
      setRowThreeAnimation(main_view_card_styles.second_card__row_one_animation_hide)
    }

  });

  return (
    <div className={main_view_card_styles.fourth_card}>
        <div
          id="EXPERIENCE_CARD_ROW_ONE"
          className={`${main_view_card_styles.fourth_card__row_one} ${rowOneAnimation}`}
        >
            <div className="img">
              <img src={bvd} alt="" style={{borderRadius: "8px"}} />
              <img
                src={bvd_logo} alt=""
                className={main_view_card_styles.bvdLogoStyle}
              />
            </div>
            <div className="text">
              <h2>
                Spolupracujeme s kvalitnými lokálnymi dodávateľmi
              </h2>
            </div>
        </div>
        <div
          id="EXPERIENCE_CARD_ROW_TWO"
          className={`${main_view_card_styles.fourth_card__row_two} ${rowTwoAnimation}`}
        >
            <div>
              <p>
                Vlčie sirupy sú ručne vyrábané sirupy plne vitamínov. 100% prírodné.
                Vyrábané z čerstvého ovocia a ovocných štiav.
              </p>
            </div>
            <div>
              <h1>
                DELIGHTFUL
              </h1>
              <h1>
                EXPERIENCE
              </h1>
              <img src={vlcie} alt="" />
              <img
                src={vlcie_logo}
                alt=""
                className={main_view_card_styles.vlcieLogoStyle}
              />
            </div>
            <div>
              <div>
                {/* <img src={vlcie_logo} alt="" style={vlcieLogoStyle} /> */}
              </div>
            </div>
        </div>
        <div
          id="EXPERIENCE_CARD_ROW_THREE"
          className={`${main_view_card_styles.fourth_card__row_three} ${rowThreeAnimation}`}
        >
            <div className="img">
              <img src={coffein} alt="" style={{borderRadius: "8px"}} />
              <img
                src={coffein_logo}
                alt=""
                className={main_view_card_styles.coffeinLogoStyle}
              />
            </div>
            <div className="text">
              <h2>
                COFFEEIN
              </h2>
            </div>
        </div>

    </div>
  )
}

export default ExperienceCard