import pizza from "../assets/images/header_logo.png"

import hola_maracana from "../assets/images/e_shop/hola_maracana.jpg"
import i_spent_night_in_maracana from "../assets/images/e_shop/i_spent_night_in_maracana.jpg"
import maracana_t_shirt from "../assets/images/e_shop/maracana_t_shirt.jpg"

import coffein from "../assets/images/e_shop/coffein_1.jpg"
import coffein_honduras from "../assets/images/e_shop/coffein_honduras.jpg"
import lucky_gin from "../assets/images/e_shop/lucky_gin.jpg"

export const shopItems = {
  link: "https://maracana.sumupstore.com/",
  rowOne: [
    {
      img: hola_maracana,
      title: "Tričko Hola Maracana",
      price: "14,90 €"
    },
    {
      img: i_spent_night_in_maracana,
      title: "Tričko I Spent Night In Maracana",
      price: "14,90 €"
    },
    {
      img: maracana_t_shirt,
      title: "Tričko Maracana",
      price: "14,90 €"
    }
  ],
  rowTwo: [
    {
      img: coffein,
      title: "Káva Coffeein",
      price: "6,90 €"
    },/*
    {
      img: lucky_gin,
      title: "Lúčky GIN",
      price: "16,90 €"
    }, */
    {
      img: coffein_honduras,
      title: "Káva Coffeein Honduras",
      price: "6,90 €"
    }
  ]
}