
import {
  AiFillPhone
} from "react-icons/ai"

/* import { instagramLink } from "../../config/contact" */
/* import { visible_sections } from "../../config/sectionConfig" */
import classes from "../../assets/styles/components/header.module.scss"

const HeaderMenuRight = (props) => {
  const className = props.className
  /* const visibleSectionsObjToArray = Object.values(visible_sections).filter(val => val.hiddenOnPc && val.visibile) */

  const phone = "0908 604 640"
  const handlePhoneClick = (phone) => {
    var a = document.createElement('a')
    a.style.display = "none"
    a.href = `tel:${phone}`
    a.click()
    a.removeChild()
  }

 /*  const scrollTo = (dest_element_id) => {
    document
      .getElementById(dest_element_id)
      .scrollIntoView({behavior: 'smooth'}, true)
    setTimeout(() => {
      props.onCloseMenu()
    }, 200);
  } */

  /* const redirect = () => {
    window.open(instagramLink, '_blank')
  } */

  return (
    <>
      {/* {
        visibleSectionsObjToArray.map((obj) =>
          <li
            className={className}
            onClick={() => scrollTo(obj.id)}
          >
            {
              obj.title
            }
          </li>
        )
      } */}
      {/* <li
        className={className}
        onClick={() => scrollTo("STAFF_ID")}
      >
        Staff
      </li>
      <li
        className={className}
        onClick={() => scrollTo("EVENTS_ID")}
      >
        Events
      </li> */}
      {/* <li className={className}>
        <AiFillPhone
          size="30px"
          onClick={redirect}
        />
      </li> */}

      <li
        className={className && classes["no-link"]}
      >
        Súkromná akcia v Maracane ?
      </li>
      <AiFillPhone
        size="30px"
        style={{marginRight: "-30px"}}
        className={classes["no-link"]}
      />
      <li
        className={className}
        onClick={() => {handlePhoneClick(phone)}}
      >
        { phone }
      </li>
      {/* <li className={className}>
        0915 863 043
      </li> */}
    </>
  )
}

export default HeaderMenuRight