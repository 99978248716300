
export const pivkoList = [
  {
    title: "Desinka -10°",
    volume: "300/500 ml",
    price: "1,20 €/1,70 €",
    outlined: true,
    outlinedTop: true,
  },
  {
    title: "Dvanástka - 12°",
    volume: "300/500 ml",
    price: "1,40 €/1,90 €",
    outlined: true,
  },
  {
    title: "Špeciál",
    volume: "podľa ponuky 400 ml",
    price: "2,30 €",
    outlined: true,
  },
  {
    title: "Zlatý Bažant Radler nealko",
    volume: "300/500 ml",
    price: "1,20 €/1,70 €",
    outlined: true,
    outlinedBottom: true
  },
  {
    title: "Corona",
    volume: "Fľaškové 355 ml",
    price: "2,50 €"
  },
  {
    title: "Desperados",
    volume: "Fľaškové 350 ml",
    price: "2,20 €"
  },
  /* {
    title: "Strongbow",
    volume: "Podľa ponuky 440 ml",
    price: "1,30 €"
  }, */
  {
    title: "Zlatý Bažant nealko",
    volume: "Fľaškové 330/500 ml",
    price: "1,60 €"
  },
  {
    title: "Pilsner Urquell",
    volume: "Podľa ponuky 500 ml",
    price: "1,80 €"
  },
  {
    title: "Heineken",
    volume: "Podľa ponuky 500 ml",
    price: "1,80 €"
  }
]

export const vodkaList = [
  {
    title: "Vodka 40 %",
    volume: "40 ml",
    price: "1,10 €"
  },
  {
    title: "Finlandia 40 %",
    volume: "40 ml",
    price: "1,50 €"
  },
  {
    title: "Absolut 40 %",
    volume: "40 ml",
    price: "1,70 €"
  },
]

export const destilatyList = [
  {
    title: "Slivovica Jelínek 50 %",
    volume: "40 ml",
    price: "1,90 €"
  },
  {
    title: "Hruškovica Jelínek 40 %",
    volume: "40 ml",
    price: "1,90 €"
  },
  {
    title: "Trnkovica Jelínek 42 %",
    volume: "40 ml",
    price: "1,90 €"
  },
  {
    title: "Marhuľovica BVD 45 %",
    volume: "40 ml",
    price: "3,30 €"
  },
]

export const likerList = [
  {
    title: "Fernet Citrus 27 %",
    volume: "40 ml",
    price: "1,40 €"
  },
  {
    title: "Jägermeister 35 %",
    volume: "40 ml",
    price: "1,90 €"
  },
  {
    title: "Tatra Tea Citrus 32 %",
    volume: "40 ml",
    price: "1,60 €"
  },
  {
    title: "Tatra Tea Citrus 47 %",
    volume: "40 ml",
    price: "1,70 €"
  },
  {
    title: "Tatra Tea Citrus 52 %",
    volume: "40 ml",
    price: "1,80 €"
  },
  {
    title: "Tatra Tea Citrus 62 %",
    volume: "40 ml",
    price: "1,90 €"
  }
]


/* export const tequilaList = [
  {
    title: "Tequilla Pepe Lopez 40 %",
    volume: "40 ml gold/silver",
    price: "1,80 €"
  }
] */