import classes from "../../assets/styles/components/footer.module.scss"
import {
  BiCopy
} from "react-icons/bi"
/* import {
  BsFacebook,
  BsTwitter,
} from "react-icons/bs"
import {
  AiFillInstagram
} from "react-icons/ai"

import { instagramLink } from "../../config/contact" */

// import downloadButton from "../../assets/images/google_play_download.png"

import { days } from "../../config/openingHours"

const Footer = () => {
  const address = "Hankovce 32, 067 12 Hankovce"
  const email = "info.maracanacafe@gmail.com"
  const phone = "0908 604 640" // aj 0915 863 043
  const phone2 = "0915 863 043"

  const handleCopyEmail = () => {
    navigator.clipboard.writeText(email)
  }

  const handlePhoneClick = (phone) => {
    var a = document.createElement('a')
    a.style.display = "none"
    a.href = `tel:${phone}`
    a.click()
    a.removeChild()
  }

  return (
    <div className={classes.footer_wrap}>
      <div className={classes.footer}>
        <div
          className={classes.footer__opening_hours}
        >
          <h2>
            Otváracie hodiny
          </h2>
          <div
            className={classes.footer__opening_hours_wrap}
          >
            {
              days.map((day, index) =>
                <div
                  key={index}
                  className={classes.footer__opening_hours_day}
                >
                  <h4>
                    { day.day }
                  </h4>
                  <h4>
                    { day.opening }
                  </h4>
                </div>
              )
            }

          </div>
        </div>
        <div
          className={classes.footer__contact_us}
        >
          <div>
            <h2>
              Kontaktuj nás
            </h2>
            <div
              className={classes.footer__contact_us_contact}
            >
              <div
                className={classes.footer__contact_us_contact_element}
              >
                <h4>
                  { address }
                </h4>
              </div>
              <div
                className={classes.footer__contact_us_contact_element}
              >
                <h4>
                  { email }
                </h4>
                <BiCopy
                  className={classes.footer__contact_us_contact_element_button}
                  onClick={handleCopyEmail}
                />
              </div>
              <div
                className={classes.footer__contact_us_contact_element}
              >
                <h4 onClick={() => {handlePhoneClick(phone)}}>
                  { phone }
                </h4>
              </div>
              <div
                className={classes.footer__contact_us_contact_element}
              >
                <h4
                  onClick={() => {handlePhoneClick(phone2)}}
                  className={classes.footer__contact_us_contact_element.phone_number}
                >
                  { phone2 }
                </h4>
              </div>

            </div>
          </div>
          <div>
          <h2>
              Prevádzkovateľ
            </h2>
            <div
              className={classes.footer__contact_us_contact}
            >
              <div
                className={classes.footer__contact_us_contact_element}
              >
                <h4>
                  Procax s.r.o
                </h4>
              </div>
              <div
                className={classes.footer__contact_us_contact_element}
              >
                <h4>
                  IČO: 51759462
                </h4>
              </div>
              <div
                className={classes.footer__contact_us_contact_element}
              >
                <h4>
                  DIČ: 2120772896
                </h4>
              </div>
              <div
                className={classes.footer__contact_us_contact_element}
              >
                <h4>
                  Kukorelliho 1499/24 066 01 Humenné
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.footer_map_wrap}>
        <iframe
          title="Maracana map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2616.1328161584674!2d21.947495!3d49.027080899999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473eb3c10ed26783%3A0xa406bc0689142aa9!2sMaracana%20cafe%20%26%20bar%20%26%20ice%20cream!5e0!3m2!1ssk!2suk!4v1678270911787!5m2!1ssk!2suk"
          className={classes.footer_map}
          style={{
            "border":"0",
            borderRadius: "8px"
          }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  )
}

export default Footer