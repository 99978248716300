import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Default from "./layouts/Default";
import ErrorPage from "./components/pages/ErrorPage";
import ErrorLayout from "./layouts/ErrorLayout";
import TagManager from "react-gtm-module";
import { useEffect } from "react";

function App() {

  const tagManagerArgs = {
    gtmId: 'GTM-WBQN88J'
  }

  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
  })
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Default />}>
          <Route index element={<HomePage />} />
        </Route>
        {/* Add a catch-all route for invalid URLs */}
        <Route path="/error" element={<ErrorLayout><ErrorPage /></ErrorLayout>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
