export const visible_sections = {
  "about_us": {
    title:"O Nás",
    id: "ABOUT_US_ID",
    hiddenOnPc: false,
    visibile: true
  },
  "local_suppliers": {
    title:"Naše značky",
    id: "LOCAL_SUPPLIERS_ID",
    hiddenOnPc: false,
    visibile: true
  },
  "menu":{
    title:"Menu",
    id: "MENU_ID",
    hiddenOnPc: false,
    visibile: true
  },
  "shop":{
    title:"E-Shop",
    id: "SHOP_ID",
    hiddenOnPc: false,
    visibile: false
  },
  "gallery":{
    title:"Galéria",
    id: "GALLERY_ID",
    hiddenOnPc: false,
    visibile: true
  },
  "staff":{
    title:"Personál",
    id: "STAFF_ID",
    hiddenOnPc: true,
    visibile: false
  },
  "events":{
    title:"Eventy",
    id: "EVENTS_ID",
    hiddenOnPc: false,
    visibile: true
  }
}

