// eslint-disable-next-line import/no-anonymous-default-export


export const kavickaList = [
  {
    title: "Espresso / Lungo",
    volume: "7 g",
    price: "1,80 €"
  },
  {
    title: "Cappuccino",
    volume: "7 g",
    price: "2,00 €"
  },
  {
    title: "Caffe latte",
    volume: "7 g",
    price: "2,00 €"
  },
  {
    title: "Double Espresso",
    volume: "14 g",
    price: "2,50 €"
  },
  {
    title: "Flatwhite",
    volume: "14 g",
    price: "2,80 €"
  },
  {
    title: "Sypaný čaj",
    volume: "300 ml",
    price: "2,00 €"
  },
  {
    title: "Horúca čokoláda",
    volume: "300 ml",
    price: "2,00 €"
  },
  {
    title: "Horúci džús",
    volume: "250 ml",
    price: "1,80 €"
  },
  /* {
    title: "Med / Mlieko",
    volume: "20 g / 20 ml",
    price: "0,30 €"
  } */
]

/* export const cajList = [
  {
    title: "Čaj",
    volume: "330 ml",
    price: "1,50 €"
  },
  {
    title: "Sypaný čaj",
    volume: "330 ml",
    price: "2,00 €"
  },
  {
    title: "Horúca čokoláda",
    volume: "330 ml",
    price: "2,00 €"
  },
  {
    title: "Hot Cappy",
    volume: "250 ml",
    price: "1,80 €"
  }
] */

export const pizzaList = [
  {
    title: "MARACANA",
    volume: "450g Par. základ, syr, šunka, kukurica ",
    price: "6,20 €"
  },
  {
    title: "MARCO",
    volume: "450g Par. základ, syr, slanina, saláma, udený syr",
    price: "6,80 €"
  },
  {
    title: "QUATRO FORMAGGIO",
    volume: "420g Par. základ, eidam, hermelín, niva, parmezán",
    price: "6,80 €"
  },
  {
    title: "INFERNO",
    volume: "480g Par. základ, syr, saláma, klobása, feferóny",
    price: "7,20 €"
  },
  {
    title: "NAPOLETA",
    volume: "500g Sm. základ, syr, hermelín, šunka, šampiňóny, kukurica",
    price: "7,40 €"
  },
]

export const pochutinyList = [
  {
    title: "Tyčinky",
    volume: "45g al. 1,11",
    price: "1,00 €"
  },
  {
    title: "Pistácie",
    volume: "75g al. 8",
    price: "2,60 €"
  },
  {
    title: "Arašidy solené",
    volume: "100g al. 5, 8",
    price: "1,40 €"
  },
  {
    title: "Zemiakové lupienky",
    volume: "70 g al. 5, 8",
    price: "1,70 €"
  },
  {
    title: "Arašidové chrumky",
    volume: "60 g al. 5, 8",
    price: "1,20 €"
  },
 /*  {
    title: "Žuvačky",
    volume: "14 g",
    price: "1,00 €"
  },
  {
    title: "Milka čokoláda",
    volume: "100 g al. 1, 7",
    price: "1,80 €"
  },
  {
    title: "Toffifee",
    volume: "100 g al. 1, 6, 7",
    price: "3,30 €"
  } */
]