export const limonadyDrinkyList = [
  {
    title: "Limonády vlčie sirupy",
    volume: "Nealko limonády - podľa ponuky 0,04 l",
    price: "3,40 €"
  },
  {
    title: "Gin Tonic",
    volume: "Gin & Vlčí sirup 0,04 l",
    price: "4,40 €"
  },
  {
    title: "Aperol",
    volume: "Aperol & Prosecco 0,04 l",
    price: "4,40 €"
  },
  {
    title: "Cuba Libre",
    volume: "Rum & CocaCola 0,04 l",
    price: "4,40 €"
  },
]


export const nealkoList = [
  {
    title: "Kofola Originál",
    volume: "300/500 ml",
    price: "1,20€/1,60€"
  },
  {
    title: "Coca Cola",
    volume: "330 ml",
    price: "1,80€"
  },
  {
    title: "Fanta",
    volume: "330 ml",
    price: "1,80 €"
  },
  {
    title: "Sprite",
    volume: "330 ml",
    price: "1,80 €"
  },
  {
    title: "Cappy",
    volume: "podľa ponuky 250 ml",
    price: "1,80 €"
  },
  {
    title: "Fuze Tea",
    volume: "podľa ponuky 250 ml",
    price: "1,80 €"
  },
  {
    title: "Kinley Tonic",
    volume: "podľa ponuky 250 ml",
    price: "1,80 €"
  },
  {
    title: "Natura minerálna voda",
    volume: "300 ml",
    price: "1,40 €"
  },
  {
    title: "Monster Energy",
    volume: "500 ml",
    price: "2,30 €"
  },
  {
    title: "Romerquelle",
    volume: "Citrónová tráva 330 ml",
    price: "1,80 €"
  },
  {
    title: "ISO Fruit",
    volume: "500ml",
    price: "1,80 €"
  }
]

export const paniniList = [
  {
    title: "Šunkové",
    volume: "Maslo, šunka, syr - 420 g",
    price: "3,50 €"
  },
  {
    title: "Syrové",
    volume: "Maslo, 2 druhy syra",
    price: "3,50 €"
  },

]
export const sishaList = [
  {
    title: "Vodná fajka",
    volume: "Info u obsluhy - Dostupná od 18 rokov - 15 g",
    price: "10,00 €"
  },
]
export const vinkoList = [
  {
    title: "Biele",
    volume: "1 dcl - podľa ponuky",
    price: "1,60 €"
  },
  {
    title: "Červené",
    volume: "1 dcl - podľa ponuky",
    price: "1,60 €"
  },
]


